export const menu = [
  {
    title: 'Beranda',
    path: '/'
  },
  {
    title: 'Tentang Kami',
    path: '/about-us'
  },
  {
    title: 'Kabar Terbaru',
    path: '/blogs'
  },
  {
    title: 'Struktur Nasional',
    path: '/struktur-organisasi'
  },
  {
    title: 'Kontak',
    path: '/contact'
  }
];
